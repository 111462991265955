/* SocialNetworkSkeleton.css */

/* Estilos para o esqueleto da rede social durante o carregamento */
.social-network-skeleton {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    /* margin: 0; */
  }
  
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ddd;
    margin-right: 10px;
  }
  
  .user-details {
    display: flex;
    flex-direction: column;
  }
  
  .username {
    width: 100px;
    height: 12px;
    background-color: #e0e0e0;
    margin-bottom: 6px;
    border-radius: 4px;
  }
  
  .timestamp {
    width: 80px;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  /* Estilos para as postagens */
  .skeleton-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
  }
  
  .post-skeleton {
    background-color: #f5f5f5;
    /* margin-bottom: 20px; */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .post-header {
    width: 60%;
    height: 10px;
    background-color: #e0e0e0;
    margin-bottom: 12px;
    /* border-radius: 4px; */
  }
  
  .post-content {
    width: 100%;
    height: 100px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  